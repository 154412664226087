<template>
    <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="grey darken-3"
                            icon
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :loading="getModalLoading"
                            :disabled="isNew"
                            @click="menu = true"
                    >
                        <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                </template>
                <v-list dense rounded nav>
                    <v-list-item link class="px-4" @click.stop="deleteDialog">
                        <v-list-item-icon>
                            <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
        <v-card-text class="pt-4">
            <v-form v-model="formValid" ref="form">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field type="text"
                                      filled
                                      label="Назва"
                                      v-model="name"
                                      hide-details
                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                      :class="name ? '' : 'req-star'"
                                      color="grey"/>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field type="text"
                                      filled
                                      label="Повна назва"
                                      v-model="full_name"
                                      hide-details
                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                      :class="full_name ? '' : 'req-star'"
                                      color="grey"/>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field type="text"
                                      filled
                                      label="Телефон"
                                      v-model="phone"
                                      hide-details
                                      color="grey"/>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
                   class="button-accept">
                <v-icon left>mdi-content-save</v-icon>
                Зберегти
            </v-btn>
            <v-btn depressed text tile color="grey" @click="closeModal">
                <v-icon left>mdi-close</v-icon>
                Відмінити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import {
    CREATE_COUNTER_CONTRACTOR, UPDATE_COUNTER_CONTRACTOR, DELETE_COUNTER_CONTRACTOR
} from "@/store/actions/counter_contractor";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'counter_contractor_modal_delete'

export default {
    name: "HWP_Modal_CounterContractor",
    mixins: [ModalComponentMixin],
    data() {
        return {
            name: this.item.name,
            full_name: this.item.full_name,
            phone: this.item.phone,
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
            this.itemId = this.item.id
            this.name = this.item.name
            this.full_name = this.item.full_name
            this.phone = this.item.phone

            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        deleteDialog() {
            this.menu = false
            const payload = {
                text: 'Підтвердіть вилучення контрагента для обслуг. ліч.',
                accept_button: true,
                id: modalDeleteId
            }
            this.$store.dispatch(QUESTION_SHOW, payload)
        },
        crud_item() {
            this.formValid = this.$refs.form.validate()
            if (!this.formValid) {
                this.formValid = false
                this.$store.commit(ALERT_SHOW, {
                    text: 'Назва та Повна назва мають бути заповнені',
                    color: 'error lighten-1'
                })
                return
            }

            const payload = {
                name: this.name,
                full_name: this.full_name,
                phone: this.phone,
            }

            if (this.isNew) {
                this.$store.dispatch(CREATE_COUNTER_CONTRACTOR, payload)
                    .then(el => {
                        if (el) {
                            this.closeModal()
                        }
                    })
            } else {
                payload.id = this.itemId
                this.$store.dispatch(UPDATE_COUNTER_CONTRACTOR, payload)
                    .then(el => {
                        if (el) {
                            this.closeModal()
                        }
                    })
            }
        },
        watch_item() {
            this.watcher = this.$watch(
                'item',
                {
                    deep: true,
                    immediate: true,
                    handler(payload) {
                        this.name = payload.name
                        this.full_name = payload.full_name
                        this.phone = payload.phone
                    }
                }
            )
        },
        watch_modal_answer() {
            this.delete_watcher = this.$watch(
                'modalAnswer',
                {
                    handler(payload) {
                        if (payload.id === modalDeleteId) {
                            if (payload.answer) {
                                this.$store.dispatch(DELETE_COUNTER_CONTRACTOR, this.itemId)
                                    .then(ok => {
                                        if (ok) {
                                            this.closeModal()
                                        }
                                    })
                            }
                        }
                    }
                }
            )
        },
        watch_dialog() {
            this.dialog_watcher = this.$watch(
                'dialog',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.watch_item()
                            this.watch_modal_answer()
                        } else {
                            if (this.watcher) {
                                this.watcher()
                            }
                            if (this.delete_watcher) {
                                this.delete_watcher()
                            }
                        }
                    }
                }
            )
        }
    },
}
</script>
